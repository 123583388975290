
import { CorpDetailEntityModel, CorpEntityModel, CorpQueryModel } from '@/entity-model/corp-entity';
import { PermissionEntityModel } from '@/entity-model/permission-entity';
import { PermissionType } from '@/model/enum';
import { ICRUDQ } from '@/model/interface';
import { get, post, del } from './request';

const URL_PATH = `${AUTH_BASE_REQUEST_PATH}/tenant`;
class CorpService implements ICRUDQ<CorpEntityModel, CorpQueryModel> {
    async create(model: CorpEntityModel):Promise<CorpEntityModel> {
        const url = `${URL_PATH}/save`;
        const params = model.toService();
        const res = await post(url, params);
        return res;
    }

    async retrieve(modelId: string):Promise<any> {
        const url = `${URL_PATH}/${modelId}`;
        const res = await get(url);
        return new CorpDetailEntityModel().toModel(res);
    }

    async update(model: CorpEntityModel):Promise<CorpEntityModel> {
        const url = `${URL_PATH}/save`;
        const params = model.toService();
        const res = await post(url, params);
        return res;
    }

    async delete(model: CorpEntityModel):Promise<CorpEntityModel> {
        const url = `${URL_PATH}/${model.id}`;
        const res = await del(url);
        return res;
    }

    async query(query?: CorpQueryModel, page?: number, limit?: number):Promise<any> {
        const url = `${URL_PATH}/list`;
        const params = Object.assign({ page, limit }, query?.toService());
        const res = await post(url, params);
        res.items = _.map(res.items, item => item = new CorpEntityModel().toModel(item));
        return res;
    }

    async getCorpPermission(id: string):Promise<any> {
        const url = `${URL_PATH}/permissions/selected/${id}`;
        const res = await get(url);
        return res;
    }

    async getCorpPermissionByType(id: PermissionType):Promise<any> {
        const url = `${URL_PATH}/permissions/${id}`;
        const res = await get(url);
        return _.map(res, item => item = new PermissionEntityModel().toModel(Object.assign({ level: 1 }, item)));
    }

    async saveCorpPermission(id: string, permissionList: Array<PermissionEntityModel>):Promise<any> {
        const url = `${URL_PATH}/permission/${id}`;
        const params = _.map(_.filter(_.flatMapDepth(permissionList, 'children', 1), item => item.select || item.Indeterminate), item => item.toRoleService());
        const res = await post(url, params);
        return res;
    }

    async saveCorpDetail(detailModel: CorpDetailEntityModel):Promise<any> {
        const url = `${URL_PATH}/saveInfo`;
        const params = detailModel.toService();
        const res = await post(url, params);
        return res;
    }
}

export default new CorpService();
